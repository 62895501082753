import React from "react";
import PropTypes from "prop-types";

const Modal = ({ children, onClose }) => (
  <div
    style={{
      height: "100vh",
      width: "100vw",
      backgroundColor: "rgba(0,0,0,.9)",
      position: "absolute",
      top: "0",
      left: "0",
      zIndex: 10,
    }}
  >
    <div
      style={{
        maxWidth: "360px",
        minWidth: "180px",
        margin: "auto",
        padding: "20px",
        border: "#db9020 solid 3px",
        borderRadius: "10px",
        backgroundColor: "#0a0a0d",
      }}
    >
      <span
        onClick={onClose}
        onKeyPress={onClose}
        tabIndex="0"
        role="button"
        style={{
          float: "right",
        }}
      >
        Close
      </span>
      {children}
    </div>
  </div>
);

Modal.propTypes = {
  onClose: PropTypes.func,
  children: PropTypes.shape({}),
};

Modal.defaultProps = {};

export default Modal;
