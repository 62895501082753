import PropTypes from "prop-types";
import React from "react";

const ContactForm = ({ onSubmit, success, failure }) => (
  <>
    {!success && !failure && (
      <>
        <h1>Contact Us</h1>
        <form
          style={{
            background: "#0a0d0d",
            textAlign: "left",
            paddingLeft: "20px",
          }}
          action="#"
          onSubmit={onSubmit}
        >
          <label htmlFor="name">Name:</label>
          <br />
          <input
            type="text"
            name="name"
            autoComplete="name"
            maxLength="36"
            placeholder="Your Name"
            required
            style={{
              width: "285px",
            }}
          />
          <br />
          <label htmlFor="replyEmail">Contact Email:</label>
          <br />
          <input
            type="email"
            placeholder="Contact Email"
            name="replyEmail"
            autoComplete="email"
            style={{
              width: "285px",
            }}
            required
          />
          <br />
          <label>
            Enter Message Here (Max 500 chars):
            <textarea
              name="message"
              col="100"
              rows="5"
              style={{
                width: "285px",
                resize: "none",
                padding: "10px",
                border: "1px solid #db9020",
                boxShadow: "1px 1px 1px #db9020",
                fontSize: ".8rem",
              }}
              required
              maxLength={500}
            />
          </label>
          <br />
          <button type="submit">Submit</button>
        </form>
      </>
    )}
    {success && (
      <p
        style={{
          marginTop: "20px",
        }}
      >
        {" "}
        Thanks for your interest in Veselkaworks, we'll be reaching out shortly.
      </p>
    )}
    {failure && (
      <p
        style={{
          marginTop: "20px",
        }}
      >
        Something went wrong, please try again later, if you continue running
        into issues please email us at:
        <br />{" "}
        <a
          href={"mailto:info@veselkaworks.com"}
          target="_blank"
          rel="noopener noreferrer"
        >
          {" "}
          info@veselkaworks.com{" "}
        </a>
        <br /> Sorry for any inconvenience
      </p>
    )}
  </>
);

ContactForm.propTypes = {
  onSubmit: PropTypes.func,
  success: PropTypes.bool,
  failure: PropTypes.bool,
};

export default ContactForm;
