import PropTypes from "prop-types";
import React from "react";
import Modal from "./modal";
import ContactForm from "../ContactForm/contactform";

class UnderConstruction extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showContactForm: false,
      showSuccess: false,
      showFailure: false,
    };

    this.handleContactUsClick = this.handleContactUsClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.onSubmitContactForm = this.onSubmitContactForm.bind(this);
  }

  handleContactUsClick(event) {
    if (event.preventDefault) {
      event.preventDefault();
    }
    if (this.state.showContactForm) {
      this.setState({
        showContactForm: false,
      });
    } else {
      this.setState({
        showContactForm: true,
      });
    }
  }
  handleClose(event) {
    this.setState({
      showContactForm: false,
      showSuccess: false,
      showFailure: false,
    });
  }

  onSubmitContactForm(event) {
    if (event.preventDefault) {
      event.preventDefault();
    }
    let form = event.target;
    const formData = {
      name: form.elements["name"].value,
      replyEmail: form.elements["replyEmail"].value,
      message: form.elements["message"].value,
    };
    fetch("https://zqodrp7c47.execute-api.us-west-2.amazonaws.com/Prod/send", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    }).then(
      response => {
        if (response.ok) {
          this.setState({
            showSuccess: true,
            showFailure: false,
          });
        } else {
          this.setState({
            showFailure: true,
            showSuccess: false,
          });
        }
      },
      response => {
        this.setState({
          showFailure: true,
          showSuccess: false,
        });
        console.log(response);
      }
    );
  }

  render() {
    const { localization } = this.props;
    return (
      <div
        style={{
          color: "white",
          paddingTop: "25vh",
        }}
      >
        {this.state.showContactForm && (
          <Modal onClose={this.handleClose}>
            <ContactForm
              onSubmit={this.onSubmitContactForm}
              success={this.state.showSuccess}
              failure={this.state.showFailure}
            />
          </Modal>
        )}
        <h1>{localization.coming_soon}</h1>
        <hr
          style={{
            height: "5px",
            backgroundImage:
              "linear-gradient(to right, transparent, #db9020, transparent)",
          }}
        ></hr>
        <h2>
          {localization.building_door_lineone}
          <br></br>
          {localization.building_door_linetwo}
        </h2>
        <div>
          <button
            style={{
              borderColor: "#db9020",
              backgroundColor: "transparent",
              borderRadius: "5px",
              borderWidth: "3px",
              color: "#db9020",
              marginBottom: "20px",
              fontSize: "30px",
            }}
            onClick={this.handleContactUsClick}
          >
            {localization.contact_us}
          </button>
          <br></br>
          <div>
            <h4>{localization.our_services_header}</h4>
            <div
              style={{
                margin: "0 auto",
                width: "375px",
              }}
            >
              <ul
                style={{
                  columns: 2,
                  textAlign: "left",
                }}
              >
                <li>{localization.services_logo}</li>
                <li>{localization.services_infographic_design}</li>
                <li>{localization.services_print_design}</li>
                <li>{localization.services_advertising}</li>
                <li>{localization.services_business_cards}</li>
                <li>{localization.services_letterheads}</li>
                <li>{localization.services_presentation_design}</li>
                <li>{localization.services_web_graphics}</li>
                <li>{localization.services_web_development}</li>
                <li>{localization.services_package_design}</li>
              </ul>
            </div>
            <h4>{localization.information_header}</h4>
            <h5>{localization.general_inquiry}</h5>
            <p
              style={{
                textTransform: "uppercase",
              }}
            >
              {localization.contact_email}
            </p>
          </div>
        </div>
      </div>
    );
  }
}

UnderConstruction.propTypes = {
  localization: PropTypes.shape({
    coming_soon: PropTypes.string,
    building_door_lineone: PropTypes.string,
    building_door_linetwo: PropTypes.string,
    contact_us: PropTypes.string,
    our_services_header: PropTypes.string,
    information_header: PropTypes.string,
    general_inquiry: PropTypes.string,
    contact_email: PropTypes.string,
    services_logo: PropTypes.string,
    services_letterheads: PropTypes.string,
    services_infographic_design: PropTypes.string,
    services_print_design: PropTypes.string,
    services_advertising: PropTypes.string,
    services_business_cards: PropTypes.string,
    services_presentation_design: PropTypes.string,
    services_web_graphics: PropTypes.string,
    services_web_development: PropTypes.string,
    services_package_design: PropTypes.string,
  }),
};

UnderConstruction.defaultProps = {};

export default UnderConstruction;
